// Code borrowed from https://github.com/zeit/next.js/blob/canary/examples/with-google-analytics

// This setup also uses tips from:
// https://www.elvisduru.com/blog/how-to-customize-ant-design-theme-in-nextjs

import App from 'next/app';
import Router from 'next/router';
import * as Sentry from '@sentry/node';
import { RewriteFrames } from '@sentry/integrations';
// This import of an empty .less styling file is a hack, in order
// to avoid an issue that nextjs has with its router. See
// https://github.com/vercel/next.js/issues/5264#issuecomment-524656570
// require('../assets/empty.less');
require('../assets/antd-custom.less');

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  let integrations = [];
  if (
    process.env.NEXT_IS_SERVER === 'true'
  ) {
    // For Node.js, rewrite Error.stack to use relative paths, so that source
    // maps starting with ~/_next map to files in Error.stack with path
    // app:///_next
    integrations.push(
      new RewriteFrames({
        iteratee: (frame) => {
          // frame.filename = frame.filename.replace(
          //   NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR,
          //   'app:///'
          // );
          frame.filename = frame.filename.replace('.next', '_next');
          return frame;
        },
      })
    );
  }

  let release = process.env.NEXT_PUBLIC_SENTRY_RELEASE;
  Sentry.init({
    enabled: true, // process.env.NODE_ENV === 'production',
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    integrations,
    release,
  });
}

// Track client-side page views with Segment
Router.events.on('routeChangeComplete', url => {
  window.analytics.page(url);
  var event = new Event("smartcuts-urlchange");
  document.dispatchEvent(event);
});

export default App;
